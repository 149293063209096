<template>
  <a name="timecodewords"></a>
  <div class="mt-4">
    <h2>Отредактируйте транскрибацию</h2>
    <p class="alert alert-info">
      Исправьте возможные опечатки в тексте. <b>Изменения сохраняются автоматически.</b> Вы также можете выбрать ключевые слова – они будут дополнительно подсвечены в итоговом видео. <br><br>

      <i class="bi bi-eye"></i> - нажимайте на такую кнопку, чтобы добавить или убрать фразу из конечного клипа.



    </p>

    <spinner-component :visible="wordsLoading" class="mt-4"></spinner-component>

    <div v-if="!wordsLoading && wordsData.videos">
      <div v-for="(video, video_index) in wordsData.videos" class="video" :key="video_index">
        <div :ref="setVideoHeaderRef" :data-index="video_index" class="video-header">
          <h2>Видео #{{video_index+1}} </h2>
          <p>Продолжительность клипа: {{ calculateTotalDuration(video.segments) }} сек</p>
          <button class="btn bnt-sm btn-outline-warning ml-2" @click="toggleHiddenPhrases(video)">
            {{ video.showHidden ? 'Скрыть неактивные фразы' : 'Показать скрытые фразы' }}
          </button>          
        </div>

        <div v-for="segment in video.segments" :key="segment.start" class="segment" :class="(segment.selected)?'selected':''">
          <!-- Добавляем кнопку переключения -->
          <div class="inner d-flex align-items-start" v-if="segment && video.showHidden || (!video.showHidden && segment.selected)">
            <div class="flex-fill">
              <div v-for="word in segment.words" :key="word.s" class="word-input">
                <input
                  type="text"
                  v-model="word.word"
                  v-autosize
                  :class="{ 'keyword': word.wk === 'true' }"
                  :style="{ width: `calc(${word.word.length}ch + 0px)` }"
                  @focus="setActiveWord(word, segment, $event)"
                  @blur="clearActiveWord"
                  @input="saveWord(segment, word)"
                />
              </div>
            </div>

            <button class="btn " :class="{'btn-success': segment.selected, 'btn-outline-warning': !segment.selected}" @click="toggleSelectedSegment(segment)">
              <i class="bi" :class="{ 'bi-eye': segment.selected, 'bi-eye-slash': !segment.selected }"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="activeWord" class="word-actions" :style="{ top: buttonPosition.top + 'px', left: buttonPosition.left + 'px' }">
      <button class="btn" :class="activeWord.wk === 'true'?'btn-primary':'btn-light'" @mousedown.prevent="toggleWK(activeWord)">{{ activeWord.wk === 'true' ? 'Убрать выделение' : 'Выделить' }}</button>
    </div>
  </div>
</template>


<script>
import { computed } from 'vue';
import { mapActions, mapGetters, useStore } from 'vuex';
import SpinnerComponent from '@/components/SpinnerComponent.vue';

const updateWidth = (el) => {
  el.style.width = 'auto';
  el.style.width = `${el.scrollWidth}px`;
};

export default {
  props: {
    materialId: null
  },
  components: {
    SpinnerComponent,
  },
  directives: {
    autosize: {
      bind(el) {
        el.style.width = 'auto';
        el.style.width = `${el.scrollWidth}px`;
      },
      unbind(el) {
        el.removeEventListener('input', updateWidth);
      }
    }
  },
  setup() {
    const store = useStore();
    const hasEditAccess = computed(() => store.getters['user/hasEditAccess']);
    return {
      hasEditAccess
    };
  },
  data() {
    return {
      wordsLoading: false,
      activeWord: null,
      activeSegment: null,
      buttonPosition: { top: 0, left: 0 },
    };
  },
  computed: {
    ...mapGetters('app', ['isMobile']),
    ...mapGetters("article", ["getArticleItem", "getArticleTimecodeWords"]),
    articleItem: { 
      get() {
        return this.getArticleItem || {};
      },
      set(value) {
        this.updateArticleItem(value);
      },
    },
    wordsData() {
      return this.getArticleTimecodeWords;
    }
  },
  methods: {
    ...mapActions('article', ['fetchArticleTimecodeWords', 'saveMaterialWords', 'saveMaterialVideosSegments']),
    calculateTotalDuration(segments) {
      let totalSeconds = Math.round(segments
        .filter(segment => segment.selected)
        .reduce((total, segment) => {
          return total + segment.words.reduce((segmentTotal, word) => {
            return segmentTotal + (word.e - word.s);
          }, 0);
        }, 0));

      // Проверяем, превышает ли общая длительность 60 секунд
      if (totalSeconds >= 60) {
        const minutes = Math.floor(totalSeconds / 60); // Получаем количество полных минут
        const seconds = totalSeconds % 60; // Получаем остаток в секундах

        // Возвращаем строку в формате "мм:сс"
        return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
      } else {
        // Возвращаем строку только с секундами, если меньше минуты
        return `0:${totalSeconds < 10 ? '0' + totalSeconds : totalSeconds}`;
      }
    },


    async toggleSelectedSegment(segment){
      segment.selected = !segment.selected;

      try {
        await this.saveMaterialVideosSegments({
          materialId: this.materialId,
          videos: this.wordsData
        });
        console.log('Videos segments saved successfully');
      } catch (error) {
        console.error('Error saving word:', error);
      }      
    }, 
    toggleHiddenPhrases(video) {
      video.showHidden = !video.showHidden;
    },
    setActiveWord(word, segment, event) {
      this.activeWord = word;
      this.activeSegment = segment;
      const rect = event.target.getBoundingClientRect();
      this.buttonPosition = { top: rect.bottom + window.scrollY, left: rect.left + window.scrollX };
    },
    clearActiveWord() {
      this.activeWord = null;
      this.activeSegment = null;
    },
    async toggleWK(word) {
      word.wk = word.wk === 'true' ? 'false' : 'true';
      try {
        await this.saveWord(this.activeSegment, word);
        console.log('Word saved successfully');
      } catch (error) {
        console.error('Error saving word:', error);
      }
    },
    async saveWord(segment, word) {
      try {
        await this.saveMaterialWords({
          materialId: this.materialId,
          segmentStart: segment.start,
          word
        });
        console.log('Word saved successfully');
      } catch (error) {
        console.error('Error saving word:', error);
      }
    },
    async fetchWords() {
      this.wordsLoading = true;
      try {
        await this.fetchArticleTimecodeWords(this.materialId);
      } catch (error) {
        console.error('Error fetching words:', error);
      } finally {
        this.wordsLoading = false;
      }
    }
  },
  mounted() {
    this.fetchWords();
  }
};
</script>



<style>
.segment {
  margin-bottom: 16px;
  opacity: 0.4;
}

.segment .inner {
  padding: 10px;
}

.segment.selected {
    border-radius: 8px;
    background: #78ff7926;
    opacity: 1;
}

.segment.hidden {
  display: none;
}

.word-input {
  display: inline-block;
  margin-right: 8px;
}

.word-input input {
  box-sizing: content-box;
  margin-bottom: 6px;
  border-radius: 6px;
  border: 0;
  padding: 4px 10px;

}

.word-input input.keyword {
  border: 1px solid #bbbefd;
  border-radius: 6px;
  color: #b79dff;
}

.word-actions {
  position: absolute;
  display: flex;
  gap: 10px;
}

.toggle-selected-btn {
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.toggle-selected-btn .fa {
  color: #555;
}

.video-header {
  position: sticky;
  top: 0;
  z-index: 999;
  background: #212529;
  width: 100%;
  padding-bottom: 10px;
}

.btn-sm{
  font-size: 0.8em;
}

</style>
