import axiosConfig from '@/lib/axiosConfig';
import { API_URL } from '@/config.js';
import store from '@/store'; // Import the store instance

const articleModule = {
  namespaced: true,
  state: {
    articleItem: null,
    articleTimecodeWords: []
  },
  mutations: {
    setArticleItem(state, article) {
      state.articleItem = article;
    },
    updateArticleItem(state, payload) {
      // Объединяем существующие свойства articleItem с новыми данными
      state.articleItem = { ...state.articleItem, ...payload };
    },
    setArticleTimecodeWords(state, words) {
      state.articleTimecodeWords = words;
    }
  },
  actions: {
    fetchArticle({ commit }) {
      return axiosConfig
        .get(API_URL, {
          params: {
            action: 'get_article',
          },
        })
        .then(response => {
          const article = response.data.article;
          commit('setArticleItem', article);

          if (typeof article !== 'undefined' && article.channel_id){
            store.dispatch('channel/fetchChannel', { channelId: article.channel_id });
          }
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },
    fetchArticleTimecodeWords({ commit }, materialId) {
      return axiosConfig
        .get(API_URL, {
          params: {
            action: 'get_timecodewords',
            materialId: materialId
          }
        })
        .then(response => {
          commit('setArticleTimecodeWords', response.data.result);
        })
        .catch(error => {
          console.error('Error fetching article timecode words:', error);
          throw error;
        });
    },

    saveMaterialVideosSegments( _, { materialId, videos }) {
      return axiosConfig
        .post(API_URL, {
          params: {
            action: 'save_material_videos_segments',
            materialId: materialId,
            videos: videos
          }
        })
        .then(response => {
          return response.data;
        })
        .catch(error => {
          console.error('Error saving word:', error);
          throw error;
        });
    },    
    saveMaterialWords( _, { materialId, segmentStart, word }) {
      return axiosConfig
        .post(API_URL, {
          params: {
            action: 'save_timecode_word',
            materialId: materialId,
            segmentStart: segmentStart,
            word: word
          }
        })
        .then(response => {
          return response.data;
        })
        .catch(error => {
          console.error('Error saving word:', error);
          throw error;
        });
    },
    saveArticle({ state }) {
      const { articleItem } = state;
      return axiosConfig
        .post(API_URL, {
          params: {
            action: 'save_article',
            articleContent: articleItem.content,
            articleSummary: articleItem.text_summary,
            articleChecklist: articleItem.text_checklist,
            articleTitle: articleItem.title,
            articleVideoUrl: articleItem.video_url,
            articleDescription: articleItem.description,
            articleDontConvertMedia: articleItem.dont_convert_media,
          },
        })
        .then(response => {
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },
    requestArticleAccess({ state }) {
      const { articleItem } = state;
      return axiosConfig
        .post(API_URL, {
          params: {
            action: 'request_article_access',
            articleID: articleItem.id,
          },
        })
        .then(response => {
          return response.data;
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },
  },
  getters: {
    getArticleItem(state) {
      return state.articleItem;
    },
    getArticleTitle(state) {
      return state.articleItem ? state.articleItem.title : '';
    },
    getArticleContent(state) {
      return state.articleItem ? state.articleItem.content : '';
    },
    getArticleTimecodeWords(state) {
      return state.articleTimecodeWords;
    }
  },
};

export default articleModule;
