<template>
  <div class="modal modal-onfront" tabindex="-1" role="dialog" :class="{ 'd-block': showModal }">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">Прикрепить видео/аудио к материалу</h2>
          <button type="button" class="btn btn-light" @click="cancelModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form v-if="user">
            <div class="alert alert-info" v-if="showAlert">
              <p>Укажите ссылку на Youtube-видео или загрузите видео (или аудио) к этому материалу. Текст из медиа-файла будет извлечен автоматически и добавлен в этот материал.</p>
              <div v-if="articleItem.content">
                <div>Внимание! При добавлении или изменении видео (или аудио) текущий контент материала будет перезаписан текстом из нового файла. Если хотите сохранить текущий контент, поставьте галочку.</div>
                <div class="form-check mt-3">
                  <input class="form-check-input" type="checkbox" v-model="dontConvertMedia" id="dontConvertMedia">
                  <label class="form-check-label" for="dontConvertMedia">Сохранить текущий контент материала.</label>
                </div>
              </div>
            </div>

            <div class="form-group mb-2" v-if="!modalMediaUploading">
              <label for="mediaFile">Youtube-ссылка</label>
              <div><input v-model="videoUrl" class="form-control mb-3"></div>
            </div>

            <spinner-component :visible="modalMediaUploading" class="mt-4"></spinner-component>

            <div class="text-success mb-2" v-if="modalMediaUploaded">✅ Файл успешно загружен!</div>
            <div class="form-group mb-2" v-if="!modalMediaUploading">

              <label for="mediaFile">или загрузите файл (видео или аудио)</label>
              <div><input type="file" id="mediaFile" @change="handleMediaUpload" accept="video/*,audio/*"></div>
            </div>

            <div class="mt-5 d-flex justify-content-between align-items-center">
              <button type="button" class="btn btn-primary" @click="saveMedia" :disabled="modalMediaUploading">Сохранить</button>
              <button type="button" class="btn btn-secondary" @click="cancelModal">Закрыть</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <overlay-component :visible="showModal" class="onfront"></overlay-component>
</template>

<script>
import { mapActions } from "vuex";
import { useToast } from "vue-toastification";
import OverlayComponent from '@/components/OverlayComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';

export default {
  components: {
    OverlayComponent,
    SpinnerComponent
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    user: {
      // данные о пользователе, если необходимо
    },
    articleItem: {
      // данные о материале, если необходимо
    },
    mediaUploading: {
      type: Boolean,
      required: true
    }
  },
  emits: ['cancel', 'close', 'save', 'update:showModal', 'update:mediaUploading', 'update:articleItem'],

  data() {
    return {
      videoUrl: '',
      modalMediaUploading: false,
      modalMediaUploaded: false,
      dontConvertMedia: false
    };
  },
  computed: {
    showAlert() {
      return this.articleItem.content && this.showModal;
    }
  },
  methods: {
    ...mapActions('material', ['saveMaterialMedia']),    
    saveMedia() {
      // Update article item in the store before saving

      this.$store.commit('article/updateArticleItem', {
        video_url: this.videoUrl, // Предполагаем, что ответ сервера включает извлечённый текст
        dont_convert_media: this.dontConvertMedia
      });


      // Dispatch saveArticle action to save the updated article
      this.$store.dispatch("article/saveArticle")
        .then(() => {
          const toast = useToast();
          toast.success("Статья успешно обновлена", {
            timeout: 2000,
          });
          this.$emit('save');

        })
        .catch((error) => {
          console.error("Ошибка при сохранении статьи:", error);
        });

      this.closeModal();
    },
    cancelModal() {
      this.$emit('cancel');
      this.closeModal();
    },
    
    closeModal() {
      this.$emit('close');
    },
    async handleMediaUpload(event) {
      const files = event.target.files;
      this.modalMediaUploading = true;
      this.modalMediaUploaded = false;
      console.log('handleMediaUpload');
      if (!files.length) return;

      const file = files[0];
      const formData = new FormData();
      formData.append('mediaFile', file);

      this.$emit('update:mediaUploading', this.modalMediaUploading);

      try {
        const response = await this.saveMaterialMedia({
          materialId: this.articleItem.id,
          mediaForm: formData,
          dontConvertMedia: this.dontConvertMedia
        });

        // Optionally update article content based on media conversion result
        //if (!this.dontConvertMedia) {
          this.$store.commit('article/updateArticleItem', {
            video_url: '',
            telegram_item_link: response.telegram_item_link,
          });
        //}

        this.modalMediaUploading = false;
        this.modalMediaUploaded = true;
        this.$emit('update:mediaUploading', this.modalMediaUploading);
        this.saveMedia(); // Call saveMedia to save all updates
      } catch (error) {
        this.modalMediaUploaded = false;
        this.modalMediaUploading = false;
        this.$emit('update:mediaUploading', this.modalMediaUploading);

        console.error('Error uploading file:', error);
      }
    }

  }
};
</script>


<style scoped>
/* стили для модального окна */
</style>
